































































import { ValidationRules } from '@/api/schema';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import * as vr from '@/helpers/validation';

import PromoCampaignFormMixin from './PromoCampaignFormMixin';

export default {
  name: 'PromoCampaignFormAward',
  mixins: [PromoCampaignFormMixin],

  components: {
    Tooltip
  },

  computed: {
    rules(): ValidationRules {
      return {
        rewardProgramCheckboxGroup:
          this.enabledFields.fixedTokensReward ||
          this.enabledFields.extraH2eReward ||
          this.enabledFields.extraP2eReward
            ? []
            : [(v) => !!v],
        extraP2eRewardInput: this.enabledFields.extraP2eReward
          ? [...this.positiveNumberRules, (v) => vr.lte(v, 5)]
          : [],
        extraH2eRewardInput: this.enabledFields.extraH2eReward
          ? [
              ...this.positiveNumberRules,
              (v) => vr.gte(v, 5001),
              (v) => vr.lte(v, 20000)
            ]
          : [],
        tokensRewardInput: this.enabledFields.fixedTokensReward
          ? this.positiveNumberRules
          : []
      };
    }
  }
};
